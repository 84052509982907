@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Import Fuse core library
@import 'src/assets/scss/core';

// Import app.theme.scss
@import 'app/app.theme';

/* @import '~swiper/dist/css/swiper.min.css'; */

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// tailwind base file changes base color
mat-expansion-panel {
    .mat-expansion-panel-header {
        .mat-expansion-indicator {
            &:after {
                border-color: #3b3e47 !important;
            }
        }
    }
}

mat-header-cell {
    @include media-breakpoint('gt-sm') {
        display: flex !important;
    }
}

.full-width {
    width: 100% !important;
}

.white {
    background-color: white;
}

.gray {
    background-color: #f5f5f5;
}

.icon-18 {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px;
    line-height: 18px;
    min-height: 18px;
    font-size: 18px;
}

.table-toolbar {
    justify-content: flex-end;

    @include media-breakpoint-down('xs') {
        justify-content: space-between;
    }
}

.avatar-initials {
    background-color: #adafb3;
    color: #ffffff !important;
}

.search-input-wrapper {
    position: relative;
    top: 8px;

    &.rounded {
        top: 0px;
        background: #e6e9e7;
        border-radius: 100px;
        width: 220px;
        margin-right: 0;
        margin-left: auto;

        &.light {
            background-color: #f5f5f5;
        }

        .mat-icon {
            font-size: 18px;
            width: 18px;
            height: 18px;
            min-width: 18px;
            min-height: 18px;
            line-height: 18px;
        }

        .search-input + .clear {
            bottom: 8px;
        }

        .mat-form-field-flex {
            padding: 0 40px 0 10px;
        }
        .mat-form-field-underline {
            display: none;
        }
        .mat-form-field-wrapper {
            padding: 4px !important;
        }
        .mat-form-field-infix {
            border: 0 !important;
            width: 120px;
        }

        .mat-form-field-wrapper {
            @include media-breakpoint-down('sm') {
                width: 100%;
            }
        }

        @include media-breakpoint-down('sm') {
            width: 100%;
        }

        .search-label {
            top: 12px;
            left: 18px;
            @include media-breakpoint-down('md') {
                position: relative;
            }
        }

        .mat-form-field-flex {
            .mat-form-field-label {
                span {
                    font-size: 16px;

                    @include media-breakpoint-up('sm') {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &.active {
        width: 744px;

        @include media-breakpoint-down('md') {
            width: 100%;
        }
    }

    .mat-form-field-flex {
        padding: 0 25px;

        .mat-form-field-label {
            span {
                font-size: 14px;
            }
        }
    }

    .search-label {
        top: 19px;
        left: 30px;
        position: relative;

        @include media-breakpoint-down('md') {
            left: 0;
            position: absolute;
        }
    }
}

.edit-description-btn {
    margin-left: -16px !important;
}

.filter-certificate-wrapper {
    width: 180px;
}

mat-toolbar-row.table-toolbar,
mat-toolbar-row.form-toolbar {
    color: #3b3e47;
    background-color: #f9f9f9;

    &.white {
        background-color: white;
    }

    .toolbar-title {
        font-weight: 500;
    }
}

.header-buttons {
    .mat-raised-button,
    button {
        margin-left: 8px;

        &:first-child {
            margin-left: 0;
        }

        @include media-breakpoint-down('xs') {
            margin-left: 4px;
        }
    }
}

.mat-raised-button,
.mat-flat-button {
    &.secondary {
        background-color: var(--maxbrain-100) !important;
    }
}

.participant-view-illustration {
    height: 200px !important;
    width: 200px !important;
    background-size: cover;

    svg {
        width: 100%;
        height: 100%;

        #rect1,
        #rect2,
        #rect3,
        #rect4,
        #rect6 {
            fill: var(--maxbrain-500);
        }

        #rect5 {
            fill: var(--maxbrain-100);
        }
    }
}

.no-options-wrapper {
    background: #f9f9f9;
    padding: 24px;

    p {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
        color: #464b54;
    }
}

@media (min-width: 600px) {
    .participant-view-illustration {
        height: 100px !important;
        width: 100px !important;
    }
}

.mat-icon-button {
    &.square {
        width: 44px;
        height: 34px;
        line-height: 34px;
        border-radius: 2px;

        .mat-icon {
            height: 18px;
            width: 18px;
            min-width: 18px;
            min-height: 18px;
            font-size: 18px;
            line-height: 18px;
        }
    }
}

.stats-wrapper {
    height: 100%;
    border-radius: 2px;

    .info-icon {
        min-width: 16px;
        width: 16px;
        height: 16px;
        font-size: 10px;
        font-family: none;
        color: #fff;
        background: #adafb3;
        border-radius: 100%;
    }

    .chart-wrapper {
        height: 100%;

        .icon-wrapper {
            width: 24px;
            height: 24px;
            border-radius: 2px;

            .mat-icon {
                font-size: 18px;
                width: 18px;
                height: 18px;
                min-width: 18px;
                min-height: 18px;
                line-height: 18px;
                fill: inherit;

                svg {
                    fill: inherit;

                    path {
                        fill: inherit;
                    }
                }
            }
        }

        .chart-title-wrapper {
            .chart-title {
                color: #464b54;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.1px;
            }
        }
    }
}

.no-pointer-events {
    pointer-events: none;
}

.mat-icon-box {
    background: #fff !important;
    border: 1px solid #ecedef !important;
    border-radius: 4px !important;

    &.see-more {
        width: 60px !important;
    }

    .mat-icon {
        line-height: 20px;
    }
}

.mat-menu-panel {
    &.watchers-menu {
        min-width: 200px;
        .mat-menu-item {
            &:hover {
                .remove-icon {
                    display: block;
                }
            }

            .remove-icon {
                display: none;
            }
        }

        .avatar {
            min-width: 24px;
            height: 24px;
            width: 24px;
            font-size: 10px;
        }

        p {
            color: #737373;
        }
    }
}

.preview-tag {
    border-radius: 100px;
    background: #fff;
    color: #3b3e47;
    padding: 4px 10px;
}

// Grow pages
.grow-toolbar {
    background: none !important;
    h2 {
        color: #464b54;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
    }
}

@media (min-width: 1080px) {
    .grow-toolbar {
        h2 {
            color: #464b54;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
        }
    }
}

//GROW HOMEPAGE NAVIGATION
.navigation-container {
    .mat-tab-header-pagination-chevron {
        border-color: rgba(255, 255, 255, 0.87) !important;
    }
}

// mat spinner button
.spinner-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-container {
    display: inline-block;
    position: relative;
}

.mat-image-info-tooltip {
    white-space: pre-wrap;
    text-align: center;
    font-size: 12px;
}

.nav {
    .nav-link.no-text {
        .mat-icon {
            margin-right: 0;
        }
    }
}

@media (min-width: 1280px) {
    .nav {
        &.hasRightSideNavigation {
            > .nav-item:nth-last-child(-n + 3) {
                > a {
                    .nav-link-title {
                        display: none;
                    }
                }
            }

            > .nav-item:nth-last-child(3) {
                flex-grow: 1;
            }
        }

        &.module-form-nav {
            > .nav-item:nth-last-child(2),
            > .nav-item:nth-last-child(1) {
                position: relative !important;
                background-color: unset !important;

                > .nav-link {
                    > .nav-link-icon {
                        margin-left: 0px;
                        margin-right: 0px;
                    }
                }
            }
            > .nav-item:nth-last-child(1),
            > .nav-item:nth-last-child(2) {
                margin-right: 8px;
                > .nav-link {
                    padding: 0px 24px !important;
                }
            }
        }

        > .nav-item:nth-last-child(3),
        > .nav-item:nth-last-child(2) {
            > .nav-link {
                padding: 0px 24px;
            }
        }
        > .nav-item:nth-last-child(1) {
            display: none;
        }
        > .nav-item:nth-last-child(2) {
            > .nav-link {
                padding: 0px 38px 0px 24px;
            }
        }
    }
}

@media (min-width: 1840px) {
    .nav {
        > .nav-item:nth-last-child(-n + 2) {
            > a .nav-link-title {
                display: block;
            }
        }
    }
}

.accordion {
    .mat-expansion-panel-header {
        height: 64px !important;

        .mat-content {
            align-items: center;
            overflow: initial !important;
            width: 100%;
            max-width: 100%;
        }
    }

    .accordion-title {
        h4 {
            margin: 0;
        }

        span {
            color: #adafb3;
            font-size: 12px;
            line-height: 16px;
        }
    }

    .accordion-content-wrapper {
        .accordion-content {
            margin-bottom: 30px;

            .key {
                width: 150px;
                font-weight: 500;
                padding-right: 20px;
                display: inline-block;
                flex: 30% 0 0;
            }
        }
    }
}

.mat-optgroup-label {
    display: none !important;
}

.timepicker-form {
    position: relative;

    .mat-input-element {
        width: 100%;
        padding: 0 5px;
    }

    .mat-icon {
        pointer-events: none;
        position: absolute;
        right: 0px;
        top: 7px;
        font-size: 20px;
        color: #666;
    }
}

.timepicker__header,
.clock-face__number > span.active {
    background-color: var(--maxbrain-700) !important;
}

.timepicker-button {
    color: var(--maxbrain-700) !important;
}

.clock-face__clock-hand {
    background-color: var(--maxbrain-700) !important;

    &:after {
        background-color: var(--maxbrain-700) !important;
    }
}

.clock-face__clock-hand_minute:before {
    border: 4px solid var(--maxbrain-700) !important;
}

.mat-pseudo-checkbox-checked::after {
    width: 8px !important;
    height: 3px !important;
}

.status-green {
    color: #a2c617;
}

.status-orange {
    color: #f5a623;
}

.close-dialog {
    cursor: pointer;
}

.example-spacer {
    flex: 1 1 auto;
}

.upload-instructions {
    display: block;
    margin-top: 15px;
    font-size: 12px;
}

.no-overflowX {
    overflow-x: hidden;
}

.file-wrapper {
    overflow-x: auto;

    .info-icon {
        cursor: pointer;

        &:hover {
            color: var(--maxbrain-700);
        }

        &.active {
            color: var(--maxbrain-700);

            &:hover {
                color: #3b3e47;
            }
        }
    }
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.overflow-visible {
    overflow: visible !important;
}

.pointer {
    cursor: pointer !important;
}

.mat-snack-bar-container.error {
    background: #f44336 !important;
    color: #ffffff !important;

    span.mat-button-wrapper {
        color: #ffffff !important;
    }
}

.avatar {
    object-fit: cover;
}

.mat-tab-nav-bar {
    @include media-breakpoint-down('xs') {
        width: 100%;
        overflow-x: hidden;
    }
}

.mat-tab-links {
    overflow-y: hidden;
    @include media-breakpoint-down('xs') {
        width: 100%;
        height: 99%;
        overflow-x: auto;
        padding-right: 15px;
    }
}

.mat-tab-nav-bar {
    &.white {
        .mat-tab-link {
            height: 64px;

            @include media-breakpoint-down('xs') {
                padding: 0 45px !important;
            }
        }
    }
}

.content-card {
    @include media-breakpoint-down('xs') {
        margin-top: 50px;
    }
}

.mat-toolbar-row.parent {
    @include media-breakpoint-down('xs') {
        position: relative;
    }

    .header-buttons {
        @include media-breakpoint-down('xs') {
            position: absolute;
            top: -55px;
            right: 0;
        }
    }
}

.mat-toolbar-row {
    padding: 0 24px !important;

    .search-input-wrapper {
        &.rounded {
            @include media-breakpoint-down('xs') {
                width: 100%;
            }

            @media screen and (min-width: 412px) {
                margin-right: 16px !important;
            }

            .search-label {
                @include media-breakpoint-down('xs') {
                    top: 12px;
                    left: 18px !important;
                    position: relative !important;
                }
            }

            .mat-form-field {
                @include media-breakpoint-down('xs') {
                    width: unset;
                }
            }
        }

        @include media-breakpoint-down('xs') {
            margin-right: 0 !important;
            margin-left: 0 !important;
            width: 200px;
        }

        .search-label {
            @include media-breakpoint-down('xs') {
                left: 0 !important;
                position: absolute !important;
            }
        }

        .mat-form-field {
            @include media-breakpoint-down('xs') {
                width: 100%;
            }
        }
    }
}

.user-header {
    @include media-breakpoint-down(xs) {
        flex-direction: column !important;
        padding-bottom: 50px !important;
    }

    .save-user-button {
        @include media-breakpoint-down(xs) {
            margin: 20px 0 !important;
        }
    }
}

.user-status-wrapper {
    position: absolute;
    right: 24px;
    top: 24px;

    @include media-breakpoint-down(xs) {
        left: 24px;
        right: initial;
        top: initial;
        bottom: 0;
    }

    .mat-icon {
        font-size: 15px;
        position: relative;
        top: 2px;

        &.icon.active {
            color: #a9ca34;
        }

        &.icon.inactive {
            color: #f44336;
        }
    }

    .user-status.active {
        color: #a9ca34;
    }

    .user-status.inactive {
        color: #f44336;
    }
}

.file-drop {
    &.over {
        border: 2px dotted #0782d0;
        background: transparent !important;
    }
}

.file-drop-container {
    .ngx-file-drop__drop-zone {
        height: 100% !important;
        border-radius: unset !important;
        border: unset !important;

        .ngx-file-drop__content {
            height: 100%;
            display: block;
            color: unset;
        }

        &.ngx-file-drop__drop-zone--over {
            border: 3px dashed var(--maxbrain-700) !important;
            background: rgba(197, 197, 197, 0.3) !important;
        }
    }

    &.certificates-list {
        .ngx-file-drop__content {
            background-color: #f9f9f9;
            border: 1px dashed #e0e0e0;
            padding: 20px 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.search-path {
    color: #737373;
    font-size: 12px;
    line-height: 13px;
    font-weight: 400;

    a {
        padding-top: 5px;
        display: inline-block;
    }
}

.mat-raised-button.short {
    line-height: 22px;
}

.mat-raised-button.min-width-auto {
    min-width: auto;
}

.cell-35 {
    flex: 0 0 35%;

    @include media-breakpoint-down('sm') {
        flex: 1;
    }
}

.cell-30 {
    flex: 0 0 30%;

    @include media-breakpoint-down('sm') {
        flex: 1;
    }
}

.header-cell-35 {
    flex: 0 0 35%;

    @include media-breakpoint-down('sm') {
        flex: 0 0 50%;
    }
}

.header-cell-30 {
    flex: 0 0 30%;

    @include media-breakpoint-down('sm') {
        flex: 0 0 50%;
    }
}

.mat-header-cell {
    font-size: 14px;
}

.mat-option {
    font-size: 14px;
}

.mat-chip-list {
    display: block;

    &.wrap {
        .mat-chip-list-wrapper {
            flex-wrap: wrap;
        }
    }

    .mat-chip-list-wrapper {
        flex-wrap: nowrap;

        .mat-standard-chip {
            padding: 4px 8px !important;
            white-space: nowrap;
            overflow: initial;
            height: 20px;
            min-height: 0;
            font-size: 11px;
            line-height: 16px;
            margin: 4px 4px;
        }
    }
}

.search-input + .clear {
    cursor: pointer;
    position: absolute;
    right: -24px;
    bottom: 4px;
}

.page-layout {
    > .header {
        height: 90px;
    }
}

.mat-list-item {
    .mat-icon {
        &.green-icon {
            svg {
                path {
                    fill: #a2c617;
                }
            }
        }
        &.blue-icon {
            svg {
                path {
                    fill: #61b3f1;
                }
            }
        }
        &.orange-icon {
            svg {
                path {
                    fill: #f5a623;
                }
            }
        }
        &.red-icon {
            svg {
                path {
                    fill: #f50808;
                }
            }
        }
        &.gray-icon {
            svg {
                path {
                    fill: #666666;
                }
            }
        }
    }
}

iframe {
    max-width: 100%;
}

// Shows dialog in Full Screen Mode on Mobile
.full-screen-modal {
    @include media-breakpoint('lt-sm') {
        height: 100vh;
        width: 100vw !important;
        max-width: 100vw !important;

        .mat-dialog-container {
            border-radius: 0;
            [mat-dialog-content] {
                max-height: calc(100vh - 56px);
            }
        }
    }
}

.participant-overview-dialog {
    .mat-toolbar-row {
        background-color: unset;
        border-bottom: none;
    }
    .mat-dialog-container {
        [mat-dialog-content] {
            padding-top: 8px !important;
            padding-bottom: 8px !important;
        }
    }
    .mat-raised-button {
        box-shadow: none !important;
    }
}

.allow-tags-overflow-dialog {
    mat-dialog-container {
        overflow: visible;

        .mat-stepper-horizontal {
            &.allow-tags-overflow {
                overflow: visible !important;
                .mat-horizontal-content-container {
                    overflow: visible !important;
                }
            }
        }
    }
}

.filter-by-overflow-dialog {
    mat-dialog-container {
        overflow: inherit;
    }
}

.mat-dialog-container {
    padding: 0px !important;
    max-height: unset !important;

    [mat-dialog-content] {
        max-height: calc(100vh - 175px);
        overflow-y: auto;

        &.allow-tags-overflow {
            overflow: visible;
        }

        &.image-library-dialog-container {
            max-height: 75vh;
            min-height: calc(100vh - 224px);
        }

        &.edit-audience-widget-container {
            min-height: calc(100vh - 224px);
        }

        &.add-audience-post-container {
            min-height: calc(100vh - 224px);
        }

        &.select-course-offering-dialog {
            max-height: unset;
        }
    }

    .action-buttons {
        padding: 24px;

        .mat-raised-button,
        button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0 !important;
            }
        }
    }
}

.round-button-container {
    button {
        background: #fff !important;
        color: #464b54 !important;
        box-shadow: none !important;
        width: 200px;
        height: 200px;
        .mat-button-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            mat-icon {
                color: #000;
            }
        }
    }

    &.disabled {
        button {
            color: #a8a8a8 !important;
        }
    }
}

body.theme-default .mat-chip.mat-standard-chip {
    color: #ffffff;

    .mat-icon {
        color: white !important;
        opacity: 1 !important;
    }
}

.header-swipper {
    .swiper-pagination-el {
        color: var(--maxbrain-700);
    }

    &.no-pagination {
        .swiper-pagination,
        .swiper-button-next,
        .swiper-button-prev {
            display: none;
        }
    }

    .swiper-pagination-bullet-active {
        background: var(--maxbrain-700) !important;
    }

    .swiper-pagination {
        bottom: 332px !important;
    }

    .swiper-pagination-bullet {
        opacity: 1;
        margin: 0 4px;
        width: 8px;
        height: 8px;
        background: #fff;
        border: none !important;
    }

    .swiper-button-next,
    .swiper-button-prev {
        cursor: pointer;
        color: var(--maxbrain-700);
        width: 58px;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);
        top: 52%;

        &[disabled] {
            display: none;
        }
    }

    .swiper-button-next {
        right: 0;
        border-radius: 4px 0px 0px 4px;
        &:after {
            content: '';
            display: block;
            width: 14px;
            height: 21px;
            background-color: #fff;
            background-repeat: no-repeat;
            -webkit-mask-image: url('assets/icons/right-chevron.svg');
            mask-image: url('assets/icons/right-chevron.svg');
            mask-repeat: no-repeat;
            mask-size: 14px;
        }
    }
    .swiper-button-prev {
        left: 0;
        border-radius: 0px 4px 4px 0px;
        &:after {
            content: '';
            display: block;
            width: 14px;
            height: 21px;
            background-color: #fff;
            background-repeat: no-repeat;
            -webkit-mask-image: url('assets/icons/left-chevron.svg');
            mask-image: url('assets/icons/left-chevron.svg');
            mask-repeat: no-repeat;
            mask-size: 14px;
        }
    }
}

.postings-swipper,
.related-postings-swipper,
.student-modules-swipper,
.alumni-swipper {
    .swiper-container {
        padding: 0 15px;
    }
    .swiper-button-next,
    .swiper-button-prev {
        opacity: 0.9;
        color: var(--maxbrain-700);
        background-image: none;
        width: 72px;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);

        &[disabled] {
            display: none;
        }
    }

    .swiper-button-next {
        top: 0;
        right: 0;
        margin-top: 0;

        &:after {
            content: '';
            display: block;
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 14px;
            height: 21px;
            background-color: #fff;
            background-repeat: no-repeat;
            -webkit-mask-image: url('assets/icons/right-chevron.svg');
            mask-image: url('assets/icons/right-chevron.svg');
            mask-repeat: no-repeat;
            mask-size: 14px;
        }
    }

    .swiper-button-prev {
        top: 0;
        left: 0;
        margin-top: 0;

        &:after {
            content: '';
            display: block;
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 14px;
            height: 21px;
            background-color: #fff;
            background-repeat: no-repeat;
            -webkit-mask-image: url('assets/icons/left-chevron.svg');
            mask-image: url('assets/icons/left-chevron.svg');
            mask-repeat: no-repeat;
            mask-size: 14px;
        }
    }
}

.header-bottom-swipper {
    .swiper-container {
        padding: 0 80px;
    }
    .swiper-button-next,
    .swiper-button-prev {
        color: var(--maxbrain-700);
        background-image: none;
        width: 50px;
        height: 60px;
    }

    .swiper-button-next {
        background-color: var(--maxbrain-700);
        background-repeat: no-repeat;
        -webkit-mask-image: url('assets/icons/right-chevron.svg');
        mask-image: url('assets/icons/right-chevron.svg');
        mask-repeat: no-repeat;
        mask-size: 60px;
    }
    .swiper-button-prev {
        background-color: var(--maxbrain-700);
        background-repeat: no-repeat;
        -webkit-mask-image: url('assets/icons/left-chevron.svg');
        mask-image: url('assets/icons/left-chevron.svg');
        mask-repeat: no-repeat;
        mask-size: 60px;
    }
}

.course-offering-product-code {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a6a6a6;
}

.job-progress-bar {
    &.mat-progress-bar {
        &.green {
            .mat-progress-bar-fill {
                &:after {
                    background: #a2c617;
                }
            }
        }
        &.blue {
            .mat-progress-bar-fill {
                &:after {
                    background: #61b3f1;
                }
            }
        }
        &.orange {
            .mat-progress-bar-fill {
                &:after {
                    background: #f5a623;
                }
            }
        }
        &.red {
            .mat-progress-bar-fill {
                &:after {
                    background: #f50808;
                }
            }
        }
        &.gray {
            .mat-progress-bar-fill {
                &:after {
                    background: #666666;
                }
            }
        }
    }
}

// IMAGE LIBRABRY DIALOG
.preview-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border: 1px dashed #a8a8a8;
    border-radius: 2px;
    cursor: pointer;

    .img-icon {
        background: url('assets/icons/ic_image-library.svg') no-repeat center;
        background-size: contain;
        height: 60px;
        width: 100%;
        margin-bottom: 12px;
    }
}

.experience-form {
    .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 0.2em 0em 0.8em 0; /* Original top/bottom value was 1em. */
    }
}

.auto-width {
    .mat-form-field {
        width: auto !important;
    }
    .mat-select-value {
        max-width: 100%;
        width: auto;
    }
}

.tabs-group {
    .mat-tab-label {
        min-width: unset;
    }
    .sub-tabs-group {
        .mat-tab-header {
            padding-left: 12px;
            border-bottom: unset !important;
        }
        .mat-tab-label {
            font-size: 12px;
            padding: 0px 12px;
            min-width: unset;
        }
    }
}

.custom-grow-button {
    align-items: center;
    background-color: var(--maxbrain-500);
    padding: 8px 14px;
    cursor: pointer;

    span {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #fff;

        &.strong {
            font-weight: 500;
        }
    }
}

.tooltip {
    border-radius: 4px;
    background-color: #464b54;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    color: #9e9e9e;
    font-size: 12px;
    line-height: 16px;
    padding: 5px;
    position: relative;
    top: -40px;
    left: 40px;
}

/*****   SELECT USERS DIALOG   *****/

app-select-users,
.select-users-wrapper {
    .search-input-wrapper .mat-form-field {
        width: 100%;
    }

    .toolbar {
        .close-dialog {
            cursor: pointer;
        }

        .example-spacer {
            flex: 1 1 auto;
        }
    }

    .description {
        color: #9e9e9e;
        font-family: Roboto;
        font-size: 12px;
        line-height: 16px;
        padding-bottom: 20px;
    }

    .mat-card {
        height: auto;
        overflow-y: auto;
        padding: 0;

        .mat-list {
            padding-top: 0;

            .mat-list-item {
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                height: 60px;

                .close {
                    cursor: pointer;
                }
            }

            .mat-list-item-content {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}

.basic-select-wrapper {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    width: 115px;
}

.full-screen-button {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    width: 47px;
    height: 48px;
    cursor: pointer;

    &.fixed {
        position: fixed;
    }
}

/*****   FILE PREVIEW   *****/

.file-arrow {
    height: 40px;
    width: 40px;
    border-radius: 27px;
    background-color: #3d464d;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.35);
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    z-index: 9;

    &.fixed {
        position: fixed;
    }

    &:hover {
        background-color: var(--maxbrain-500);
    }

    &.left {
        left: 50px;

        .mat-icon {
            left: 3px;
        }
    }

    &.right {
        margin-right: 50px;
        right: 0;
        transition: margin 0.2s ease-out;

        &.narrow {
            margin-right: 32.5%;
            transition: margin 0.1s ease-out;
        }

        .mat-icon {
            left: 1px;
        }

        @media screen and (min-width: 1280px) {
            margin-right: 119px;
        }
    }

    .mat-icon {
        color: white;
        position: relative;
        top: 12px;
        font-size: 16px;
    }
}

.file-view {
    &.is-not-pdf {
        display: block;
        box-shadow: none;
    }

    &.not-supported {
        padding: 100px 0;
        background: #ffffff;
    }

    .image,
    .video {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    .no-preview-wrapper {
        width: auto;
        margin: 0 auto;
        padding: 30px 8%;
        border: 1px solid #e0e0e0;
        border-radius: 2px;
        display: inline-block;

        h3 {
            font-weight: bold;
        }

        & > div {
            margin: 20px 0;

            span {
                color: #3b3e47;
                font-family: Roboto;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
            }
        }
    }

    img,
    video {
        max-height: calc(100vh - 190px);
    }
}

/*****   FULL CALENDAR   *****/

full-calendar {
    a {
        color: unset !important;
    }

    .fc-toolbar {
        position: relative;
        margin-top: -72px;

        .fc-button-group {
            .fc-button-primary:not(:disabled).fc-button-active:focus {
                box-shadow: none;
            }

            .fc-button {
                background: transparent;
                border: 0;

                .fc-icon {
                    color: #737373;
                }
            }
        }

        .fc-today-button {
            border: 1px solid #e0e0e0 !important;
            border-radius: 2px;
            background-color: #ffffff !important;
            color: #737373 !important;
            font-size: 14px;
            line-height: 20px;
            text-transform: capitalize;
            outline: none;
            margin-right: 24px;
        }

        .fc-toolbar-chunk {
            h2 {
                position: relative;
                top: 3px;
                color: #464b54;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
            }
        }

        .fc-toolbar-chunk {
            .fc-button-group {
                margin-left: auto;

                .fc-button {
                    border: 1px solid #e0e0e0;
                    border-radius: 2px;
                    background-color: #ffffff;
                    color: #737373;
                    font-size: 14px;
                    line-height: 20px;
                    text-transform: capitalize;
                    outline: none;

                    &.fc-button-active {
                        background: var(--maxbrain-500);
                        color: white;
                    }

                    &.fc-listWeek-button {
                        display: none;
                    }
                }
            }
        }
    }

    .fc-daygrid-day.fc-day-today {
        background-color: var(--maxbrain-100) !important;
    }

    thead tr {
        color: #737373;
        font-size: 14px;
        background-color: #f9f9f9;
        line-height: 20px;
    }

    .fc-event {
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        background-color: #ffffff;
        padding: 2px 2px 0;
        border-left: 4px solid var(--maxbrain-700);

        .fc-event-main {
            color: #3b3e47 !important;
            font-family: Roboto;
            font-size: 11px;
            line-height: 16px;
        }

        &.public {
            border-color: #737373;
        }
    }
}

.basic-select {
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    background-color: #ffffff;
}

.section-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .section-item-info {
        .section-item-ordering {
            display: inline-block;
            position: relative;
            width: 24px;

            .mat-icon {
                position: absolute;
                cursor: pointer;

                &.disabled {
                    cursor: auto;
                    color: #cecbcb;
                    pointer-events: none;
                }

                &.section-item-move-up {
                    bottom: 0px;
                }

                &.section-item-move-down {
                    top: -4px;
                }
            }
        }

        .mat-icon {
            font-size: 20px;
            height: 20px;
            width: 20px;
            min-height: 20px;
            min-width: 20px;
            line-height: 20px;
            color: #adafb3;
            vertical-align: center;

            svg {
                height: 20px !important;
            }
        }

        a {
            color: inherit;
            cursor: pointer;
        }
    }
}

.accordion-list-header {
    color: #737373;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .grade-weight {
        .info {
            position: absolute;
            right: -20px;
            top: 0;
            height: 12px;
            width: 12px;
            background-color: #737373;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            padding: 7px;
            font-size: 9px;
        }

        .mat-tooltip.tooltip {
            font-size: 10px !important;
        }
    }
}

.accordion.mat-expansion-panel {
    border: 1px solid #e0e0e0;
    border-radius: 2px 2px 0 0 !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
    margin: 6px 0;

    &.mat-expanded {
        background-color: #f9f9f9 !important;
    }
}

.mat-simple-snackbar-action {
    color: var(--maxbrain-100) !important;
}

.mat-icon-more-vert {
    color: rgba(0, 0, 0, 0.54);
}

// metrics

.card-wrapper {
    flex-wrap: wrap;
    .card {
        > div {
            border-radius: 2px;
            box-sizing: border-box;
            border: 1px solid #e0e0e0;
        }
    }
}

.toolbar-title-wrapper {
    position: relative;

    span:last-child {
        font-size: 11px;
        color: #737373;
        position: absolute;
        bottom: -16px;
    }
}

.nav.horizontal .nav-item.nav-collapsable {
    & .children.open {
        color: #3b3e47 !important;
        position: absolute;
        left: -100px;
        min-width: 160px;
        top: 100%;

        &:before {
            content: '';
            position: absolute;
            left: 132px;
            top: -15px;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            border-color: white transparent;
            border-style: solid;
            border-width: 0px 20px 15px 20px;
            height: 0px;
            width: 0px;
        }

        & > div {
            color: #3b3e47 !important;
            background: white !important;

            .nav-item {
                .nav-link {
                    path#Shape {
                        fill: #3b3e47;
                    }
                }

                &:last-child {
                    .nav-link {
                        border-top: 1px solid #e0e0e0;
                    }
                }
            }
        }
    }
}

.left-navbar.vertical-style-2 {
    .nav-item {
        .nav-link {
            path#Shape {
                fill: white !important;
            }
        }
    }
}

.nav.horizontal .nav-item.nav-collapsable .children .nav-link {
    padding: 0 16px !important;
}

.timepicker-icon {
    position: absolute;
    right: 0;
    color: rgba(0, 0, 0, 0.54);
    font-size: 20px;
}

.grey-icon {
    color: #bebfc3;
}

.green-icon {
    color: #a2c617 !important;
}

input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type='time']::-webkit-clear-button,
input[type='time']::-ms-clear {
    display: none;
}

input::-ms-clear {
    width: 0;
    height: 0;
}

.drawer-container {
    position: absolute;
    margin-right: 120px;
    height: 0;
    bottom: 0;
    right: 0;
    z-index: 1001;
}

.webinar-box {
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    padding: 20px;
    max-width: 600px;
    width: 100%;

    .copy-icon {
        color: #737373;
        cursor: pointer;
        min-height: 15px;
        min-width: 15px;
        font-size: 15px;
        margin-left: 4px;
        max-width: 15px;
        max-height: 15px;
        position: relative;
        top: 2px;
    }

    .generic-video-join-button {
        img {
            position: relative;
            top: -2px;
            max-width: 25px;
            margin-right: 8px;
            height: 24px;
            width: 24px;
        }
    }
}

.progress-drawer.mat-expansion-panel {
    .mat-expansion-panel-content {
        background-color: white;
        max-height: 461px;
        overflow-y: auto !important;

        .mat-expansion-panel-body {
            padding-top: 16px;
        }

        .error-message {
            .mat-expansion-panel-body {
                padding: 0px 16px;
            }
        }
    }
}

.group-image {
    height: 92px;
    width: 92px;
    box-sizing: border-box;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    flex: 0 0 92px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    img {
        height: 100%;
    }

    .bg-icon {
        top: -30%;
        left: -40%;
        right: 0;
        bottom: 0;
        width: 180%;
        height: 160%;
    }
}

.line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.mat-loading .mat-button-wrapper {
    visibility: hidden;
}

.mat-loading .mat-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
}

.no-hover-effect.mat-button {
    .mat-button-focus-overlay,
    &:hover {
        background-color: transparent;
    }
    .mat-ripple-element {
        display: none !important;
    }
}

.cdk-overlay-container {
    z-index: 9999;
}

#select-users-dialog {
    margin-bottom: 25px;
}

.error-text {
    color: #f44336;
}

.float-right {
    float: right !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.min-w-0 {
    min-width: 0 !important;
}

.max-w-none {
    max-width: none !important;
}

.ellipsis-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tox-tinymce-aux {
    z-index: 10000 !important;
}

.tox-tiered-menu {
    min-width: auto !important;
}

@media (min-width: 920px) {
    .postings-swipper,
    .related-postings-swipper,
    .student-modules-swipper {
        .swiper-container {
            padding: 0 80px;
        }
    }
}

.table-toolbar {
    .section-title {
        overflow: hidden;
    }
}

.schedule-icon-responsive {
    position: relative;

    .icon-tooltip-container {
        display: none;
    }

    &:hover {
        .icon-tooltip-container {
            display: flex;
            flex-direction: column;
            background: #464b54;
            width: auto;
            padding: 12px 16px;
            position: absolute;
            z-index: 999;
            top: 80px;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 24px;
            text-align: left;

            span {
                color: #fff;
                white-space: nowrap;
                font-size: 14px;
            }

            &:after {
                content: '';
                position: absolute;
                top: -14px;
                left: calc(50% - 10px);
                border-top: 10px solid transparent;
                border-bottom: 10px solid #464b54;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
            }
        }
    }
}

.registration-request-processed-container {
    padding: 0px 15px;

    h1 {
        color: #3b3e47;
        margin: 40px 0px 32px;
        font-size: 28px;
        line-height: 32px;
        font-weight: bold;
    }

    p {
        color: #737373;
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 22px;
    }
}

@media screen and (min-width: 768px) {
    .alumni-swipper {
        .swiper-container {
            padding: 0 80px;
        }
    }

    .registration-request-processed-container {
        .registration-request-processed-info-container {
            padding: 0px 30px;
            margin-top: 90px;
            margin-left: 16vw;
            max-width: 620px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

app-e-learning-module-details {
    app-e-learning-scorm-details {
        .content {
            .custom-height {
                height: calc(100vh - 225px);
                margin-bottom: -150px;
            }

            .table-toolbar {
                margin-top: -24px;
            }
        }
    }
}

app-module-form {
    app-e-learning-scorm-form {
        .content {
            .custom-height {
                height: calc(100vh - 225px);
                margin-bottom: -100px;
            }

            .table-toolbar {
                margin-top: -24px;
            }
        }
    }
}

app-module-details-content-tab-section-text img {
    @media screen and (max-width: 500px) {
        max-width: 400px;
        width: 100%;
        height: auto;
    }
}

.mat-tab-link.mat-tab-label-active {
    pointer-events: none;
}

.mat-calendar-abbr {
    text-decoration: none !important;
    cursor: inherit !important;
}

.mat-radio-button:not(.mat-radio-disabled).cdk-keyboard-focused .mat-radio-persistent-ripple,
.mat-radio-button:not(.mat-radio-disabled).cdk-program-focused .mat-radio-persistent-ripple {
    display: none !important;
}

#choose-module-type-container {
    padding-top: 24px;

    .card {
        padding: 0 24px;

        &:nth-child(odd) {
            padding-right: 12px;
        }

        &:nth-child(even) {
            padding-left: 12px;
        }

        > a {
            &:hover {
                text-decoration: none;
            }

            > div {
                border-radius: 2px;
                box-sizing: border-box;
                border: 1px solid #e0e0e0;
                text-align: center;

                &:hover {
                    border: 1px solid var(--maxbrain-700);
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
                }

                .mat-icon {
                    margin: 12px;
                    width: 48px;
                    height: auto;
                    color: #9e9e9e;

                    svg {
                        height: 48px;
                    }
                }

                .card-title {
                    margin-bottom: 12px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    color: #3b3e47;
                    display: block;
                }

                .card-description {
                    margin-bottom: 12px;
                    font-size: 12px;
                    line-height: 16px;
                    color: #9e9e9e;
                    display: block;
                }
            }
        }
    }
}

app-module-form-content-tab-section-node-list,
app-module-form-content-tab-section-eLearning,
app-module-form-content-tab-section-feedback,
app-module-form-content-tab-section-quiz,
app-module-form-content-tab-section-survey,
app-module-form-content-tab-section-text {
    .mat-toolbar-row {
        .mat-checkbox-ripple {
            display: none;
        }
    }
}

body.maintenance-on {
    .module-details-content {
        height: calc(100vh - 166px);
    }
}

@media screen and (min-width: 600px) {
    body.maintenance-on {
        .module-details-content {
            height: calc(100vh - 188px);

            &.nav-hidden {
                height: calc(100vh - 138px);
            }
        }
    }
}

button.mat-menu-trigger {
    .mat-icon {
        width: 24px;
        min-width: 24px;
        height: 24px;
        min-height: 24px;
        font-size: 24px;
    }
}

button,
a.mat-raised-button {
    &.mat-accent,
    &.accent {
        .mat-icon {
            svg path {
                fill: var(--maxbrain-600-text);
            }
        }
    }
}

app-module-form-content-tab-section-node-list,
app-module-form-content-tab-section-eLearning,
app-module-form-content-tab-section-feedback,
app-module-form-content-tab-section-quiz,
app-module-form-content-tab-section-survey,
app-module-form-content-tab-section-text {
    .mat-toolbar-row {
        .mat-checkbox-ripple {
            display: none;
        }
    }
}

.bg-icon {
    position: absolute;
    z-index: 1;

    svg {
        width: 100%;
        height: 100%;

        #rect1 {
            fill: var(--maxbrain-200);
        }
        #rect2 {
            fill: var(--maxbrain-800);
        }
        #rect3 {
            fill: var(--maxbrain-200);
        }
        #rect4 {
            fill: var(--maxbrain-600);
        }
        #rect5 {
            fill: var(--maxbrain-900);
        }
        #rect6 {
            fill: var(--maxbrain-200);
        }
    }
}

.course-offering-image-container {
    position: relative;
    min-width: 191px;
    height: 108px;

    .course-offering-image {
        position: relative;
        height: 100%;
        background-color: var(--maxbrain-700);

        &.no-image {
            position: relative;
            overflow: hidden;

            .bg-icon {
                top: -30%;
                left: -10%;
                right: 0;
                bottom: 0;
                width: 130%;
                height: 160%;
            }
        }
    }
}

.user-details-card-container {
    position: relative;
    overflow: visible;

    p {
        position: relative;
    }

    app-user-details-card {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 9999999;

        &.hidden {
            display: none;
        }
    }
}

app-active-user-list-page #users .mat-tab-body-wrapper .mat-tab-body {
    display: block !important;
}

.online-state-wrapper {
    .mat-form-field-infix {
        padding: 0.3em 0 0.8em 0;

        .mat-select {
            position: relative;
            top: 1px;
        }
    }

    .mat-form-field-prefix {
        top: 0 !important;
    }
}

.online-state-indicator {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 6px;
    bottom: 0;
    border: 2px solid;
    border-radius: 100%;

    &.online {
        border-color: #ffffff;
        background: #00d315;
    }

    &.offline {
        border-color: #a4a4a4;
        background: #ffffff;
    }
}

.postings-swipper-container,
.student-modules-swipper,
.alumni-swipper {
    .swiper-container {
        overflow: visible !important;
    }
}

.members-wrapper {
    .online-state-indicator {
        right: 3px;
        bottom: -3px;
    }
}

.upload-first-file-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #737373;
    margin-top: 4px;
    margin-bottom: 0;
}

vg-player {
    background-color: white !important;

    video {
        width: 100%;
    }
}

.period-tag {
    position: absolute;
    right: 24px;
    top: 30px;
    width: 100px;
    text-align: right;

    span {
        font-size: 10px;
        color: #7d7d7d;
        text-align: center;
        position: relative;
        right: 15px;
        z-index: 1;

        &:before {
            content: '';
            border-radius: 30px;
            position: absolute;
            background: #eaeaea;
            left: -15px;
            right: 0;
            padding-right: 30px;
            top: -6px;
            z-index: -1;
            height: 24px;
            box-sizing: content-box;
            width: 100%;
        }
    }
}

/* Tenant Dashboard */

.tenant-dashboard-charts-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .chart-wrapper {
        position: relative;
        flex: 0 0 49%;
        margin-bottom: 16px;
        padding: 24px;
        background: #ffffff;

        &.alternative {
            max-height: 40vh;
        }

        .info-icon {
            width: 16px;
            height: 16px;
            position: relative;
            top: -1px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-family: none;
            color: #fff;
            background: #adafb3;
            margin-left: 4px;
            border-radius: 100%;
        }

        @include media-breakpoint('lt-lg') {
            flex: 0 0 100%;
            min-height: 250px;
        }

        &.metrics {
            padding: 0;
            box-shadow: none;
            background: none;

            .icon-wrapper {
                flex: 0 0 56px;
                height: 56px;

                .mat-icon {
                    font-size: 30px;
                    width: 30px;
                    height: 30px;
                    min-width: 30px;
                    min-height: 30px;
                    line-height: 30px;
                }
            }

            @include media-breakpoint('lt-lg') {
                min-height: inherit;
            }
        }

        .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            flex: 0 0 32px;
            margin-right: 10px;
            border-radius: 4px;

            .mat-icon {
                font-size: 20px;
                width: 20px;
                height: 20px;
                min-width: 20px;
                min-height: 20px;
                line-height: 20px;
                fill: inherit;

                svg {
                    fill: inherit;

                    path {
                        fill: inherit;
                    }
                }
            }
        }

        .chart-title-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 24px;
            max-width: 75%;

            .chart-title {
                color: #464b54;
                font-size: 14px;
                line-height: 18px;
            }
        }

        .big-bar-chart-wrapper {
            height: 85%;
        }

        .pie-chart-wrapper {
            width: 50%;
            margin: 0 auto;
        }

        canvas {
            width: 100% !important;
            height: auto !important;
            margin: 0 auto;
        }

        .metric-card {
            display: flex;
            align-items: center;
            flex: 0 0 32.33%;
            padding: 24px;
            margin-bottom: 1%;
            background: #ffffff;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }

            &.tall {
                flex: 0 0 49.2%;

                .icon-wrapper {
                    flex: 0 0 90px;
                    height: 90px;
                }

                .content-wrapper .card-content .card-title {
                    font-size: 1.3em;
                }

                .content-wrapper .card-content .card-value {
                    font-size: 2em;
                }
            }

            .content-wrapper {
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
                width: 100%;
                max-width: 75%;
                gap: 8px;

                .card-content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-around;

                    .card-title {
                        width: 100%;
                        font-size: 14px;
                        line-height: 16px;
                        color: rgba(58, 62, 72, 0.6);
                    }

                    .card-value {
                        width: 100%;
                        font-size: 22px;
                        line-height: 26px;
                        color: #464b54;
                        font-weight: 500;
                    }
                }
            }
        }
        .no-chart-data-wrapper {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2,
            label {
                width: 100%;
                text-align: center;
            }

            .icon-wrapper {
                width: 56px;
                flex-basis: 56px;

                .mat-icon {
                    font-size: 30px;
                    width: 30px;
                    height: 30px;
                    min-width: 30px;
                    min-height: 30px;
                    line-height: 30px;
                }
            }
        }
    }
}

.sidebar-btn {
    button {
        .mat-button-focus-overlay {
            opacity: 0 !important;
        }
    }
}

/* Analitycs */

.analytics-container {
    .analytic-container {
        .analytics-title {
            color: #3b3e47;
            font-size: 16px;
            font-weight: 400;
        }

        .sections-completed-progress {
            color: var(--maxbrain-700);
            font-size: 16px;
            line-height: 20px;
        }

        .analytics-value {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #3b3e47;
        }

        .analytics-description {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #3b3e47;
        }

        .certificate-container {
            .certificate-name {
                color: #464b54;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #3b3e47;
            }

            &:hover {
                .certificate-name {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media (min-width: 600px) {
    .analytics-container {
        .analytic-container {
            .analytics-title {
                font-size: 14px;
            }

            .sections-completed-progress {
                font-size: 14px;
            }

            .analytics-value {
                font-size: 14px;
            }

            .analytics-description {
                font-size: 14px;
            }

            .certificate-container {
                .certificate-name {
                    font-size: 14px;
                }
            }
        }
    }
}

/* Analitycs */

/* Program Final Grade Square & Certificate Icon */
.program-final-grade {
    position: relative;
    padding: 8px 12px;
    border-radius: 4px;
}

.circle {
    position: relative;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 52px;
    text-align: center;
    vertical-align: middle;
    margin-right: 8px;
    flex-shrink: 0;

    &:last-child {
        margin-right: 0px;
    }

    &.gray {
        background: #f6f6f6;
        border: 1px solid #e0e0e0;

        mat-icon {
            width: 10px;
            min-width: 16px;
        }
    }

    &.white {
        background: #fff;
    }

    &.blue {
        background: linear-gradient(132.52deg, #f2f6ff 0%, #d5e4ff 96.03%);
    }
}

.custom-avatar-icon {
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid #f2f2f2;

    &.gray {
        background-color: #f9f9f9;
    }

    mat-icon {
        color: #464b54;
        min-width: 18px;
        min-height: 18px;
        line-height: 18px;
        height: 18px;
        width: 18px;
    }
}

.program-final-grade,
.circle {
    .icon-tooltip-container {
        display: none;
    }

    &.text {
        color: #3b3e47;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }

    &:hover {
        .icon-tooltip-container {
            display: flex;
            flex-direction: column;
            background: #464b54;
            width: auto;
            padding: 12px 16px;
            position: absolute;
            z-index: 999;
            top: 80px;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 24px;
            text-align: left;

            span {
                color: #fff;
                white-space: nowrap;
                font-size: 14px;
            }

            &:after {
                content: '';
                position: absolute;
                top: -14px;
                left: calc(50% - 10px);
                border-top: 10px solid transparent;
                border-bottom: 10px solid #464b54;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
            }
        }
    }
}

@media (min-width: 600px) {
    .program-final-grade,
    .circle {
        &.text {
            font-size: 14px;
        }
    }
}

.active-user-list-mat-header {
    &.cdk-drag-preview {
        display: flex;
        align-items: center;
        overflow: hidden;
        word-wrap: break-word;
        min-height: inherit;
        min-height: auto;
        padding: 2px 5px;
        color: rgba(0, 0, 0, 0.54);
        background: #f5f5f5;

        &:before {
            content: url('/assets/icons/ic_drag_indicator.svg');
            background: #f5f5f5;
            width: 20px;
            height: 100%;
            position: relative;
            top: 2px;
            margin-right: 5px;
            cursor: pointer;
            opacity: 1;
            visibility: visible;
        }
    }
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.user-properties {
    .form-toolbar {
        border-bottom: none !important;

        mat-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #606161;
            font-size: 22px;
        }
    }

    .mat-form-field-suffix {
        display: none;
    }

    .options-dropdown {
        &.alternative {
            .mat-select-arrow-wrapper {
                right: 130px;
            }
        }

        .mat-select-arrow-wrapper {
            position: absolute;
            right: 90px;
        }
    }

    .address-label {
        position: relative;
        top: 15px;
        left: 10px;
        display: block;
        margin-bottom: 30px;
    }

    .address-box {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        margin: 20px 0;
    }

    .privacy-dropdown {
        position: absolute;
        right: 10px;
        width: 60px;
        top: 7px;

        .mat-form-field-wrapper {
            padding: 0;

            .mat-form-field-infix {
                border: 0;
                background: #f5f5f5;

                .mat-select-arrow-wrapper {
                    position: relative;
                    right: 5px;
                }

                .mat-form-field-label {
                    padding-top: 28px;

                    mat-label {
                        padding: 12px 0 12px 12px;
                        background: #f5f5f5;

                        .mat-icon {
                            width: 2.5em;
                            background: #f5f5f5;

                            svg {
                                width: 25px;
                                height: 25px;
                            }
                        }
                    }
                }
            }

            .mat-form-field-underline {
                display: none;
            }
        }
    }
}

.user-edit-icon {
    position: absolute;
    right: 90px;
    top: -2px;
    font-size: 20px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    line-height: 20px;

    &.address-edit-icon {
        position: relative;
    }

    &.alternative {
        right: 10px;
    }
}

.mat-form-field {
    .success-input-icon {
        position: absolute;
        right: 120px;
        top: 2px;
        font-size: 20px;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        line-height: 20px;
        opacity: 0;
        transition: opacity 0.2s linear;

        &.more-right {
            right: 10px;
        }

        &.more-left {
            right: 150px;
        }

        &.alternative {
            right: 40px;
        }

        &.alternative-left {
            right: 90px;
        }

        &.visible {
            opacity: 1;
        }
    }
}

.keywords-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 16px;

    .keyword-tag {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 3px 16px;
        gap: 8px;
        background: #ffffff;
        border: 1px solid #a8a8a8;
        border-radius: 30px;
    }

    .mat-icon {
        font-size: 16px;
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        line-height: 16px;
    }
}

// Daterange picker

.md-drppicker {
    width: 530px !important;

    @include media-breakpoint-down('sm') {
        width: 270px !important;
    }

    .buttons_input {
        .btn {
            background-color: var(--maxbrain-600) !important;
            color: var(--maxbrain-600-text) !important;
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            box-sizing: border-box;
            position: relative;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            outline: 0;
            border: none;
            -webkit-tap-highlight-color: transparent;
            display: inline-block;
            white-space: nowrap;
            text-decoration: none;
            vertical-align: baseline;
            text-align: center;
            margin: 0;
            min-width: 88px;
            line-height: 36px;
            padding: 0 16px;
            border-radius: 2px;
            overflow: visible;
            transform: translate3d(0, 0, 0);
            transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
            font-family: Roboto, 'Helvetica Neue', sans-serif;
            font-size: 14px;
            font-weight: 500;
        }
    }

    td {
        border-radius: 4px !important;

        &.in-range {
            background-color: var(--maxbrain-100) !important;
        }

        &.active {
            background-color: var(--maxbrain-700) !important;

            &:hover {
                background-color: var(--maxbrain-700) !important;
            }
        }
    }
}

.postings-swipper-container {
    .postings-swipper {
        &:hover {
            .swiper-container {
                z-index: 2;
            }
        }
    }
}

app-user-form-visible-properties-tab,
app-profile-form-details-tab,
app-user-form-hidden-properties-tab {
    /* Chrome, Safari, Edge, Opera */
    input[matinput]::-webkit-outer-spin-button,
    input[matinput]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[matinput][type='number'] {
        -moz-appearance: textfield;
    }
}

// Disabled input fields

body.theme-default .mat-input-element:disabled,
body.theme-default .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: rgba(0, 0, 0, 0.3);
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

body.theme-default .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-image: none;
}

// SHIMMER
.shimmer-container {
    padding: 50px 20px 40px;
}

.shimmer-card {
    height: 220px;
    width: 100%;
    max-width: 385px;
}

.shimmer-title {
    width: 100%;
    height: 24px;
    background: #777;
    margin: 0px 0px 40px;
}

.animate {
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, #ebebeb 4%, #e2e2e2 25%, #ebebeb 36%);
    background-size: 1000px 100%;
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}

@media (min-width: 920px) {
    .shimmer-container {
        padding: 50px 80px 40px;
    }
}

.mat-menu-content {
    svg {
        path {
            fill: rgb(106, 106, 106);
        }
    }
}

chat-panel {
    #contacts-list-container {
        #chat {
            .contacts-list-item {
                .mat-form-field-infix {
                    display: flex;
                }
            }
        }
    }
}

// RESPEAK
.respeak-widget-button {
    z-index: 999;
    right: 80px !important;
    display: none !important;
}

body.engage .respeak-widget-button {
    display: flex !important;
}

// Revert global styles to use TinyMC content styles
.tinymce-content h1,
.tinymce-content h2,
.tinymce-content h3,
.tinymce-content h4,
.tinymce-content h5,
.tinymce-content h6 {
    font-weight: revert;
    font-size: revert;
}

.tinymce-content img {
    display: block;
}

.mat-icon.icon-20 {
    font-size: 20px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    line-height: 20px;
}

app-add-external-module-certification-dialog {
    .mat-option-text {
        display: flex;
        align-content: center;
        justify-content: space-between;
    }
}
