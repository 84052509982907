// -----------------------------------------------------------------------------------------------------
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------

:root {
    --maxbrain-warn: #f44336;
    --maxbrain-warn-70-opacity: rgba(244, 67, 54, 0.7);
    --maxbrain-warn-0-opacity: rgba(244, 67, 54, 0);
    --maxbrain-50: #f6f9e7;
    --maxbrain-100: #e7f1c4;
    --maxbrain-200: #d8e79d;
    --maxbrain-300: #c8dd75;
    --maxbrain-400: #bcd658;
    --maxbrain-500: #b0cf3a;
    --maxbrain-600: #a9ca34;
    --maxbrain-700: #a0c32c;
    --maxbrain-800: #97bd25;
    --maxbrain-900: #87b218;
    --maxbrain-A100: #f8ffe6;
    --maxbrain-A200: #e8ffb3;
    --maxbrain-A400: #d9ff80;
    --maxbrain-A700: #d1ff67;
    --maxbrain-50-text: rgba(0, 0, 0, 0.87);
    --maxbrain-100-text: rgba(0, 0, 0, 0.87);
    --maxbrain-200-text: rgba(0, 0, 0, 0.87);
    --maxbrain-300-text: rgba(0, 0, 0, 0.87);
    --maxbrain-400-text: rgba(0, 0, 0, 0.87);
    --maxbrain-500-text: white;
    --maxbrain-600-text: white;
    --maxbrain-700-text: white;
    --maxbrain-800-text: white;
    --maxbrain-900-text: white;
    --maxbrain-A100-text: rgba(0, 0, 0, 0.87);
    --maxbrain-A200-text: rgba(0, 0, 0, 0.87);
    --maxbrain-A400-text: rgba(0, 0, 0, 0.87);
    --maxbrain-A700-text: white;
} // we can also define variables with JavaScript

@function v($var) {
    @return var(--#{$var});
}

$fuse-white: (
    500: white,
    contrast: (
        500: $dark-primary-text,
    ),
);

$fuse-black: (
    500: black,
    contrast: (
        500: $light-primary-text,
    ),
);

$fuse-navy: (
    50: #ececee,
    100: #c5c6cb,
    200: #9ea1a9,
    300: #7d818c,
    400: #5c616f,
    500: #3c4252,
    600: #353a48,
    700: #2d323e,
    800: #262933,
    900: #1e2129,
    A100: #c5c6cb,
    A200: #9ea1a9,
    A400: #5c616f,
    A700: #2d323e,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

$maxbrain-palette: (
    50: v(maxbrain-50),
    100: v(maxbrain-100),
    200: v(maxbrain-200),
    300: v(maxbrain-300),
    400: v(maxbrain-400),
    500: v(maxbrain-500),
    600: v(maxbrain-600),
    700: v(maxbrain-700),
    800: v(maxbrain-800),
    900: v(maxbrain-900),
    A100: v(maxbrain-A100),
    A200: v(maxbrain-A200),
    A400: v(maxbrain-A400),
    A700: v(maxbrain-A700),
    contrast: (
        50: v(maxbrain-50-text),
        100: v(maxbrain-100-text),
        200: v(maxbrain-200-text),
        300: v(maxbrain-300-text),
        400: v(maxbrain-400-text),
        500: v(maxbrain-500-text),
        600: v(maxbrain-600-text),
        700: v(maxbrain-700-text),
        800: v(maxbrain-800-text),
        900: v(maxbrain-900-text),
        A100: v(maxbrain-A100-text),
        A200: v(maxbrain-A200-text),
        A400: v(maxbrain-A400-text),
        A700: v(maxbrain-A700-text),
    ),
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
    fuse-white: $fuse-white,
    fuse-black: $fuse-black,
    fuse-navy: $fuse-navy,
    maxbrain: $maxbrain-palette,
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Force the input field font sizes to 16px
$typography: mat-typography-config(
    $input: mat-typography-level(16px, 1.125, 400),
);

// Setup the typography
@include angular-material-typography($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import 'src/assets/scss/themes/chat-panel.theme';
@import 'src/assets/scss/themes/chat-drawer.theme';
@import 'src/assets/scss/themes/toolbar.theme';

// Define a mixin for easier access
@mixin components-theme($theme) {
    // Layout components
    @include chat-panel-theme($theme);
    @include chat-drawer-theme($theme);
    @include toolbar-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat-palette($maxbrain-palette);
$default-accent-palette: mat-palette($maxbrain-palette, 600, 400, 700);
$default-warn-palette: mat-palette($mat-red);

// Create the Material theme object
$theme: mat-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
    background-color: #f5f5f5;

    // Create an Angular Material theme from the $theme map
    @include angular-material-theme($theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);

    // Apply the theme to the user components
    @include components-theme($theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette,
    );

    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat-palette($mat-yellow, 600, 400, 700);
$yellow-light-theme-warn-palette: mat-palette($mat-red);

// Create the Material theme object
$yellow-light-theme: mat-light-theme($yellow-light-theme-primary-palette, $yellow-light-theme-accent-palette, $yellow-light-theme-warn-palette);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {
    // Generate the Angular Material theme
    @include angular-material-theme($yellow-light-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($yellow-light-theme);

    // Apply the theme to the user components
    @include components-theme($yellow-light-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $yellow-light-theme-primary-palette,
        accent: $yellow-light-theme-accent-palette,
        warn: $yellow-light-theme-warn-palette,
    );

    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat-palette($mat-blue);
$blue-gray-dark-theme-accent-palette: mat-palette($mat-blue-gray);
$blue-gray-dark-theme-warn-palette: mat-palette($mat-red);

// Create the Material theme object
$blue-gray-dark-theme: mat-dark-theme($blue-gray-dark-theme-primary-palette, $blue-gray-dark-theme-accent-palette, $blue-gray-dark-theme-warn-palette);

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {
    // Generate the Angular Material theme
    @include angular-material-theme($blue-gray-dark-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($blue-gray-dark-theme);

    // Apply the theme to the user components
    @include components-theme($blue-gray-dark-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $blue-gray-dark-theme-primary-palette,
        accent: $blue-gray-dark-theme-accent-palette,
        warn: $blue-gray-dark-theme-warn-palette,
    );

    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat-palette($mat-pink);
$pink-dark-theme-accent-palette: mat-palette($mat-pink);
$pink-dark-theme-warn-palette: mat-palette($mat-red);

// Create the Material theme object
$pink-dark-theme: mat-dark-theme($pink-dark-theme-primary-palette, $pink-dark-theme-accent-palette, $pink-dark-theme-warn-palette);

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {
    // Generate the Angular Material theme
    @include angular-material-theme($pink-dark-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);

    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $pink-dark-theme-primary-palette,
        accent: $pink-dark-theme-accent-palette,
        warn: $pink-dark-theme-warn-palette,
    );

    @include fuse-color-classes($palettes);
}
