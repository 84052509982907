@mixin chat-drawer-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    app-chat-drawer {
        .chat-drawer {
            .talk-user-name {
                .unread-count {
                    background-color: #ff0000;
                    color: #ffffff;
                }
            }
        }
    }
}
